(() => {
  const mainMenu = document.getElementById('mainMenu');
  const items = document.querySelectorAll('#mainMenu .menuItem');
  const menuIcon = document.querySelectorAll('#mainMenu .menuIcon')[0];
  const pathList = location.pathname.split('/');
  const page = `/${pathList[pathList.length - 1]}`;
  const activePageNode = document.querySelector(`[href="${page}"]`);
  const getStartedButton = mainMenu.querySelector(`.button.red.menuButton`);
  const logo = document.querySelector('.top .logo');

  menuIcon.onclick = () => {
    if(mainMenu.classList.contains('active')) {
      mainMenu.classList.remove('active');
    } else {
      mainMenu.classList.add('active');
    }
  };

  const switchDeviceMenuView = () => {
    const menuNode = document.getElementById('mainMenu');
    const isDesktop = menuNode.classList.contains('desktop');

    if(this.innerWidth < 900 && isDesktop) {
      menuNode.classList.remove('desktop');
      logo.classList.remove('desktop');
      menuNode.classList.add('mobile');
      logo.classList.add('mobile');
    } else if(this.innerWidth >= 900 && !isDesktop) {
      menuNode.classList.add('desktop');
      logo.classList.add('desktop');
      menuNode.classList.remove('mobile');
      logo.classList.remove('mobile');
    }
  };

  const handleClick = (items, { currentTarget }) => {
    const isActive = currentTarget.classList.contains('active');
    items.forEach((item) => item.classList.remove('active'));

    if(isActive) {
      currentTarget.classList.remove('active');
    } else {
      currentTarget.classList.add('active');
    }
  };

  if (location.pathname.includes('/pilot')) {
    getStartedButton.remove();
  }

  if (location.pathname.includes('/link')) {
    document.querySelector('.top .logo .link').classList.add('active');
  }

  if (location.pathname.includes('/apps')) {
    document.querySelector('.top .logo .apps').classList.add('active');
  }

  if (location.pathname.includes('/web')) {
    document.querySelector('.top .logo .web').classList.add('active');
  }

  items.forEach((item) => item.onclick = (e) => e.stopPropagation());
  items.forEach((item) => item.onclick = handleClick.bind(null, items));
  window.onresize = switchDeviceMenuView;

  if (activePageNode) {
    activePageNode.classList.add('active');

    if (activePageNode.closest('.withSubmenu')) {
      activePageNode.closest('.withSubmenu').classList.add('active');
    }
  }

  setTimeout(switchDeviceMenuView, 0);
})();
